import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import PinInput from 'react-pin-input';
import { connect } from 'react-redux';
import LoginContainer from './LoginContainer';
import AuthService from '../../api/auth';
import { verifyOTP as ActionVerifyOTP } from './duck/authDuck';
import { AppDispatch, RootState } from '../../store';
import { selctAccessToken, selectUserInfo } from './duck/selector';
import { CompanyUser } from '../../models/CompanyUser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '4rem',
      lineHeight: 1.2,
      color: '#575757',
      marginTop: 20,
      whiteSpace: 'pre-wrap',
    },
    message: {
      fontSize: '1.6rem',
      lineHeight: 1.2,
      color: '#575757',
      whiteSpace: 'pre-wrap',
    },
    error: {
      textAlign: 'center',
      alignSelf: 'center',
      color: '#e84637',
      fontSize: '1.6rem',
      width: 324,
      marginTop: 10,
      marginBottom: 55,
    },
    button: {
      width: '100%',
      marginTop: 10,
      fontSize: '1.8rem',
      fontWeight: 600,
      color: theme.palette.common.white,
      textTransform: 'capitalize',
      borderRadius: 7,
      backgroundColor: theme.palette.text.hint,
      '&:hover': {
        backgroundColor: theme.palette.text.hint,
      },
      '&.Mui-disabled': {
        color: 'white',
      },
    },
    resendIn: {
      fontSize: '1.6rem',
      color: '#838383',
      height: 40,
      alignSelf: 'center',
      marginTop: 15,
      textAlign: 'center',
      whiteSpace: 'break-spaces',
    },
    contactUs: {
      alignSelf: 'center',
      color: '#B2B2B2',
      textDecoration: 'unset',
      fontStyle: 'italic',
      fontSize: '1.4rem',
      marginTop: 30,
      textTransform: 'none',
      whiteSpace: 'pre-wrap',
    },
    switchMethod: {
      alignSelf: 'center',
      color: '#B2B2B2',
      textDecoration: 'unset',
      fontStyle: 'italic',
      fontSize: '1.4rem',
      marginTop: 30,
      textTransform: 'none',
      whiteSpace: 'pre-wrap',
      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.12)',
      },
    },
  }),
);

interface Props {
  verifyOtp: (
    channel: string,
    code: string,
    onSuccess: () => void,
    onError: () => void,
  ) => void;

  token: string | null;

  user: CompanyUser | null;
}

const OTP = ({ verifyOtp, token, user }: Props) => {
  const { t } = useTranslation('login');
  const classes = useStyles();
  const [pinCode, setPinCode] = React.useState('');
  const [codeSent, setCodeSent] = React.useState(false);
  const [canResend, setCanResend] = React.useState(0);
  const [error, setError] = React.useState('');
  const [verifySuccess, setVerifySuccess] = React.useState(false);
  const [verificationMethod, setVerificationMethod] = React.useState('');
  const pinRef = React.useRef<PinInput>();
  const countDownRef = React.useRef<any>();
  const history = useHistory();

  const onClickSendOtp = (channel: string) => {
    AuthService.resendOtp(channel)
      .then(() => {
        setCodeSent(true);
        setCanResend(120);
      })
      .catch(() => {
        setCodeSent(true);
        setCanResend(120);
      });
  };

  React.useEffect(() => {
    if (!user?.phoneNo || !user?.areaCode) {
      onClickSendOtp('email');
      setVerificationMethod('email');
    }
  }, []);

  const submitOtp = () => {
    verifyOtp(
      verificationMethod,
      pinCode,
      () => {
        setVerifySuccess(true);
      },
      () => {
        pinRef.current?.clear();
        setPinCode('');
        setError(t('invalid_verification_code'));
      },
    );
  };

  React.useEffect(() => {
    if (pinCode && error) {
      setError('');
    }
  }, [pinCode, error]);

  React.useEffect(() => {
    if (canResend > 0) {
      countDownRef.current = setTimeout(() => {
        setCanResend(canResend - 1);
      }, 1000);
    }
  }, [canResend]);

  React.useEffect(() => {
    if (verifySuccess) {
      history.push('/');
    }
  }, [verifySuccess]);

  if (!token) {
    history.replace('/');
  }

  if (!verificationMethod) {
    return (
      <LoginContainer>
        <div className={classes.title}>{t('account_verification')}</div>
        <div className={classes.message}>
          {
            'Please select the method you would like to use for verification.\n请选择收取一次性验证码的方式。'
          }
        </div>
        <Button
          variant="contained"
          disableElevation
          disableFocusRipple
          disableRipple
          className={classes.button}
          onClick={() => {
            onClickSendOtp('email');
            setVerificationMethod('email');
          }}
        >
          Email 电邮
        </Button>
        <Button
          variant="contained"
          disableElevation
          disableFocusRipple
          disableRipple
          className={classes.button}
          onClick={() => {
            onClickSendOtp('sms');
            setVerificationMethod('sms');
          }}
        >
          SMS 手机短讯
        </Button>
        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          className={classes.contactUs}
          href="mailto:issuerportal_support@hk.tricorglobal.com"
        >
          {t('contact_us')}
        </Button>
      </LoginContainer>
    );
  }

  return (
    <LoginContainer>
      <div className={classes.title}>{t('account_verification')}</div>
      <div className={classes.message}>
        {verificationMethod === 'email'
          ? t('account_verification_message')
          : t('account_verification_message_sms', {
              phoneNumber: `+${user?.areaCode} ${user?.phoneNo
                ?.slice(0, -4)
                .replace(/[0-9]/g, 'X')} ${user?.phoneNo?.slice(-4)}`,
            })}
      </div>
      <PinInput
        ref={(n) => {
          if (n) {
            pinRef.current = n;
          }
        }}
        length={6}
        style={{
          marginTop: 55,
          marginBottom: error ? 0 : 55,
          alignSelf: 'center',
        }}
        inputStyle={{
          borderRadius: 4,
          border: 'solid 2px #dedede',
          borderColor: error ? '#e84637' : '#dedede',
          backgroundColor: '#fff',
          fontSize: '3rem',
          color: '#262626',
        }}
        initialValue={pinCode}
        onChange={(value) => setPinCode(value)}
      />
      {error && <div className={classes.error}>{error}</div>}
      <Button
        variant="contained"
        disableElevation
        disableFocusRipple
        disableRipple
        className={classes.button}
        onClick={() => {
          setError('');
          onClickSendOtp(verificationMethod);
        }}
        disabled={canResend > 0}
      >
        {t(codeSent ? 'resend_verification_code' : 'send_verification_code')}
      </Button>
      <Button
        variant="contained"
        disableElevation
        disableFocusRipple
        disableRipple
        className={classes.button}
        onClick={submitOtp}
        disabled={pinCode.length < 6}
      >
        {t('confirm')}
      </Button>
      {canResend > 0 && (
        <div className={classes.resendIn}>
          {t(
            !user?.phoneNo
              ? 'request_new_code_in_email'
              : 'request_new_code_in',
            {
              time: `${Math.floor(canResend / 60)}:${(
                canResend % 60
              ).toLocaleString(undefined, {
                minimumIntegerDigits: 2,
              })}`,
            },
          )}
        </div>
      )}
      {!!user?.phoneNo && (
        <Button
          disabled={canResend > 0}
          disableElevation
          disableFocusRipple
          disableRipple
          className={classes.switchMethod}
          onClick={() => {
            setVerificationMethod((state) => {
              const channel = state === 'email' ? 'sms' : 'email';
              if (countDownRef.current && canResend < 120)
                window.clearTimeout(countDownRef.current);
              onClickSendOtp(channel);
              return channel;
            });
            setError('');
          }}
        >
          {verificationMethod === 'email'
            ? 'Use SMS for verification\n以手机短讯方式进行账号验证'
            : 'Use email for verification\n以电邮方式进行账号验证'}
        </Button>
      )}
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        className={classes.contactUs}
        href="mailto:issuerportal_support@hk.tricorglobal.com"
      >
        {t('contact_us')}
      </Button>
    </LoginContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  token: selctAccessToken(state),
  user: selectUserInfo(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  verifyOtp: (
    channel: string,
    code: string,
    onSuccess: () => void,
    onError: () => void,
  ) => dispatch(ActionVerifyOTP({ channel, code, onSuccess, onError })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OTP);
