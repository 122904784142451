import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Button, Link as MuiLink } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import LoginContainer from './LoginContainer';
import {
  acceptTnc,
  login as ActionLogin,
  logout as ActionLogout,
} from './duck/authDuck';
import { AppDispatch, RootState, useAppDispatch } from '../../store';
import {
  selectIsAuthorized,
  selectNeedOTP,
  selectTncAccepted,
} from './duck/selector';
import { LoginFormValues, LoginFormAction } from '../../models/Login';
import TncModal from '../../components/TncModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    welcome: {
      marginTop: 24,
      fontSize: '2.4rem',
      color: '#575757',
      [theme.breakpoints.up('lg')]: {
        fontSize: '4rem',
      },
    },
    forgetPassword: {
      alignSelf: 'flex-end',
      color: '#B2B2B2',
      textDecoration: 'unset',
      fontStyle: 'italic',
      fontSize: '1.4rem',
      marginTop: 8,
    },
    button: {
      width: '100%',
      marginTop: 25,
      fontSize: '1.8rem',
      fontWeight: 600,
      color: theme.palette.common.white,
      textTransform: 'capitalize',
      borderRadius: 7,
      backgroundColor: theme.palette.text.hint,
      '&:hover': {
        backgroundColor: theme.palette.text.hint,
      },
    },
    contactUs: {
      alignSelf: 'center',
      color: '#B2B2B2',
      textDecoration: 'unset',
      fontStyle: 'italic',
      fontSize: '1.4rem',
      marginTop: 8,
      marginBottom: 24,
      textTransform: 'none',
    },
    maintenance: {
      display: 'none',
      marginTop: -12,
      marginBottom: 24,
    },
    maintenanceText: {
      fontSize: '1rem',
    },
    tnc: {
      marginTop: -4,
      fontSize: '1.6rem',
      color: '#2266cb',
    },
    tncTitle: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#575757',
    },
    tncMessage: {
      fontSize: '1.6rem',
      color: '#575757',
    },
  }),
);

interface Props {
  login: (
    username: string,
    password: string,
    formActions: LoginFormAction,
  ) => void;

  logout: () => void;

  authorized: string | boolean;

  needOTP?: boolean;

  tncAccepted?: boolean;
}

export const Login: React.FC<Props> = ({
  login,
  logout,
  authorized,
  needOTP,
  tncAccepted,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('login');
  const classes = useStyles();
  const history = useHistory();
  const [loaded, setLoaded] = React.useState(false);
  const [tncOpen, setTncOpen] = React.useState(false);
  const [viewTnc, setViewTnc] = React.useState(false);

  React.useEffect(() => {
    if (authorized && !tncAccepted) {
      logout();
    }

    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  React.useEffect(() => {
    if (authorized && !tncAccepted && !tncOpen && loaded) {
      setTncOpen(true);
    }

    if (!authorized && tncOpen) {
      setTncOpen(false);
    }
  }, [authorized, tncAccepted, tncOpen, loaded]);

  React.useEffect(() => {
    if (needOTP === undefined || !tncAccepted) return;

    if (authorized) {
      if (needOTP) {
        history.push('/otp');
        return;
      }

      history.push('/');
    }
  }, [history, authorized, needOTP, tncAccepted]);

  const validate = (values: LoginFormValues) => {
    const errors: Partial<LoginFormValues> = {};
    if (!values.email) errors.email = 'Required 必须填写';
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
      errors.email = 'Invalid email address 电邮地址无效';

    if (!values.password) errors.password = 'Required 必须填写';
    return errors;
  };

  return (
    <LoginContainer>
      <div className={classes.welcome}>{t('welcome')}</div>
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={validate}
        onSubmit={(values, actions) => {
          login(values.email, values.password, actions);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <Form>
              <Field
                fullWidth
                component={TextField}
                name="email"
                label={t('email')}
              />
              <Field
                fullWidth
                component={TextField}
                name="password"
                label={t('password')}
                type="password"
              />
            </Form>
            <Link to="/recover" className={classes.forgetPassword}>
              <span>{t('forget_password')}</span>
            </Link>
            <Button
              type="submit"
              variant="contained"
              disableElevation
              disableFocusRipple
              disableRipple
              className={classes.button}
              onClick={submitForm}
              disabled={isSubmitting}
            >
              {t('sign_in')}
            </Button>
          </>
        )}
      </Formik>
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        className={classes.contactUs}
        href="mailto:issuerportal_support@hk.tricorglobal.com"
      >
        {t('contact_us')}
      </Button>
      <p className={classes.tncTitle}>Terms and Conditions:</p>
      <div className={classes.tncMessage}>
        {'By signing in, you agree to be bound by all the '}
        <MuiLink
          component="button"
          className={classes.tnc}
          onClick={() => setViewTnc(true)}
        >
          Terms and Conditions
        </MuiLink>
        {' of Tricor Issuer Portal.'}
      </div>
      <p className={classes.tncTitle}>条款及条件:</p>
      <div className={classes.tncMessage}>
        登录即表示 阁下同意受卓佳发行人在线服务平台的
        <MuiLink
          component="button"
          className={classes.tnc}
          onClick={() => setViewTnc(true)}
        >
          条款及条件
        </MuiLink>
        约束。
      </div>
      <div className={classes.maintenance}>
        <p className={classes.maintenanceText}>
          <br />
          <u>Notice of Service Suspension</u>
          <br />
          Please note that our website is temporarily suspended from 8:00 PM on 17 March 2023 to 8:00 PM on 18 March 2023 due to system maintenance.
          <br />
          We apologize for any inconvenience that may be caused and thank you for your understanding.
        </p>
        <p className={classes.maintenanceText}>
          <u>暫停服務通告</u>
          <br />
          敬請注意，本網站將於2023年3月17日下午8時正至2023年3月18日下午8時正進行系統維護工程，屆時本網站將暫停服務。
          <br />
          如造成任何不便之處，敬希原諒，並感謝您的體諒。
        </p>
      </div>
      <TncModal
        open={tncOpen}
        onClose={() => logout()}
        onClickContinue={() => dispatch(acceptTnc())}
      />
      <TncModal
        viewOnly
        open={viewTnc}
        onClose={() => setViewTnc(false)}
        onClickContinue={() => {}}
      />
    </LoginContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  authorized: selectIsAuthorized(state),

  needOTP: selectNeedOTP(state),

  tncAccepted: selectTncAccepted(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  login: (email: string, password: string, formActions: LoginFormAction) =>
    dispatch(ActionLogin({ email, password, formActions })),

  logout: () => dispatch(ActionLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
