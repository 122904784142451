import logo from './logo.png';
import logoDark from './tricor.png';
import drawerLogo from './logo2.png';
import loginBg from './login_bg.png';
import issuerPortal from './issuer_portal.png';

import { ReactComponent as home } from './navbar_home.svg';
import { ReactComponent as registerOfMember } from './navbar_rm.svg';
import { ReactComponent as entityManagement } from './navbar_em.svg';
import { ReactComponent as marketIntelligence } from './navbar_mi.svg';
import { ReactComponent as industryAnalystReport } from './navbar_iar.svg';
import { ReactComponent as shareholderAnalytics } from './navbar_sa.svg';
import { ReactComponent as eagm } from './navbar_eagm.svg';
import { ReactComponent as eproxy } from './navbar_eproxy.svg';
import { ReactComponent as invoiceManagement } from './navbar_im.svg';
import { ReactComponent as boardPortal } from './navbar_bp.svg';
import { ReactComponent as manageRequest } from './navbar_request.svg';
import { ReactComponent as ccass } from './navbar_ccass.svg';
import { ReactComponent as reports } from './report.svg';
import { ReactComponent as iam } from './iam.svg';
import { ReactComponent as key } from './key.svg';
import { ReactComponent as applications } from './applications.svg';
import { ReactComponent as feedback } from './feedback.svg';
import { ReactComponent as newsletter } from './newsletter.svg';
import { ReactComponent as userPortal } from './user-portal.svg';
import { ReactComponent as EntityListButton } from './button_em_list.svg';
import { ReactComponent as EntityChartButton } from './button_em_chart.svg';

import { ReactComponent as TopFiftyIcon } from './top_50.svg';
import { ReactComponent as IssueSummaryIcon } from './issue_summary.svg';

import { ReactComponent as AscendingIcon } from './ascending.svg';
import { ReactComponent as DescendingIcon } from './descending.svg';
import { ReactComponent as ReportsOutline } from './reports_outline.svg';
import { ReactComponent as AuditTrailReport } from './audit_trail_report.svg';
import { ReactComponent as IconUser } from './user.svg';
import { ReactComponent as ApplicationOutline } from './application_outline.svg';
import { ReactComponent as AccessTime } from './access_time.svg';

import zoomIn from './zoom_in.png';
import zoomOut from './zoom_out.png';

import boardfolioPlaceholder from './boardfolio_placeholder.png';
import eagmPlaceholder from './eagm_placeholder.png';
import industryAnalystReportPlaceholder from './industry_analyst_report_placeholder.png';
import marketIntelligencePlaceholder from './market_intelligence_placeholder.png';

import boardfolio from './boardfolilo.png';
import spot from './spot.png';
import spotWhite from './spot_white.png';

import spot1 from './spot1.png';
import spot2 from './spot2.png';
import spot3 from './spot3.png';
import spot4 from './spot4.png';

import boardfolioStepsEn from './boardfolio-steps-en.png';
import boardfolioStepsTc from './boardfolio-steps-tc.png';
import boardfolioStepsSc from './boardfolio-steps-sc.png';

import boardfoliloStepsVertical from './boardfolio-steps-vertical.png';

import boardfolio1 from './boardfolio1.png';
import boardfolio2 from './boardfolio2.png';
import boardfolio3 from './boardfolio3.png';
import boardfolio4 from './boardfolio4.png';

import eagmStepsEn from './eagm-steps-en.png';
import eagmStepsVertical from './eagm-steps-vertical.png';

import eproxyPlaceholder from './eproxy-placeholder.png';
import eproxy1 from './eproxy1.png';
import eproxy2 from './eproxy2.png';
import eproxy3 from './eproxy3.png';
import eproxy4 from './eproxy4.png';

import eproxyFeature1 from './eproxy-feature-1.png';
import eproxyFeature2 from './eproxy-feature-2.png';
import eproxyFeature3 from './eproxy-feature-3.png';
import eproxyFeature4 from './eproxy-feature-4.png';
import eproxyFeature5 from './eproxy-feature-5.png';

import dots from './dots.png';
import dollarOrange from './dollar_orange.png';
import dollarPurple from './dollar_purple.png';
import teamGreen from './team_green.png';
import teamPurple from './team_purple.png';
import peopleBlue from './people_blue.png';

import quotation from './quotation.png';
import events from './events.png';
import ccassHolding from './ccass_holding.png';
import ownershipAnalytics from './ownership_analytics.png';
import edit from './edit.png';

import { ReactComponent as HoldersListIcon } from './holders-list.svg';
import { ReactComponent as TopHoldersListIcon } from './top-holders-list.svg';
import { ReactComponent as TransferJournalIcon } from './transfer-journal.svg';

import comingSoon from './comingsoon.png';

import csCorporateActions from './cs_Corporate_Actions.png';
import csDividendPayment from './cs_Dividend_Payment.png';
import csRegistryManagement from './cs_Registry_Management.png';
import csShareholderCommunications from './cs_Shareholder_Communications.png';

import info from './info.png';

const boardfolioSteps = {
  en: boardfolioStepsEn,
  zh: boardfolioStepsTc,
  cn: boardfolioStepsSc,
};

export {
  logo,
  logoDark,
  drawerLogo,
  loginBg,
  issuerPortal,
  home,
  registerOfMember,
  entityManagement,
  marketIntelligence,
  industryAnalystReport,
  shareholderAnalytics,
  eagm,
  eproxy,
  invoiceManagement,
  boardPortal,
  manageRequest,
  ccass,
  reports,
  EntityListButton,
  EntityChartButton,
  zoomIn,
  zoomOut,
  TopFiftyIcon,
  IssueSummaryIcon,
  AscendingIcon,
  DescendingIcon,
  boardfolioPlaceholder,
  eagmPlaceholder,
  eproxyPlaceholder,
  industryAnalystReportPlaceholder,
  marketIntelligencePlaceholder,
  boardfolio,
  spot,
  spotWhite,
  spot1,
  spot2,
  spot3,
  spot4,
  eagmStepsEn,
  eagmStepsVertical,
  boardfolioSteps,
  boardfoliloStepsVertical,
  boardfolio1,
  boardfolio2,
  boardfolio3,
  boardfolio4,
  eproxy1,
  eproxy2,
  eproxy3,
  eproxy4,
  eproxyFeature1,
  eproxyFeature2,
  eproxyFeature3,
  eproxyFeature4,
  eproxyFeature5,
  dots,
  dollarOrange,
  dollarPurple,
  teamGreen,
  teamPurple,
  peopleBlue,
  quotation,
  events,
  ccassHolding,
  ownershipAnalytics,
  edit,
  HoldersListIcon,
  TopHoldersListIcon,
  TransferJournalIcon,
  comingSoon,
  csCorporateActions,
  csDividendPayment,
  csRegistryManagement,
  csShareholderCommunications,
  info,
  iam,
  userPortal,
  key,
  applications,
  newsletter,
  feedback,
  ReportsOutline,
  AuditTrailReport,
  IconUser,
  ApplicationOutline,
  AccessTime,
};
