import { LoginResponse } from '../models/LoginResponse';
import axios, { setToken, clearToken } from './index';

const login = (username: string, password: string) =>
  axios
    .post<LoginResponse>('/auth/_login', { username, password })
    .then((res) => {
      setToken(`Bearer ${res.data.token}`);
      return res;
    })
    .catch((error) => {
      if (error.response) {
        const { errorCode, message } = error.response.data;
        if (errorCode === 10003)
          throw new Error(
            'Login Fail.  Account Locked. Please ask Tricor to unlock. 登录失败。賬戶已被鎖。請通知卓佳解鎖賬戶。',
          );
      }
      throw new Error(
        'Login Fail (email/password wrong) 登录失败（电邮/密码错误）',
      );
    });

const logout = () =>
  axios.get('auth/_logout').then(() => {
    clearToken();
  });

const verifyOtp = (channel: string, code: string) =>
  axios.post('/auth/otp/verify', { channel, code }).then((res) => {
    setToken(`Bearer ${res.data.token}`);
    return res;
  });

const resendOtp = (channel: string) => axios.post('/auth/otp', { channel });

const resendInvitation = (username: string, isStaff = false) =>
  axios.post(
    `${isStaff ? 'staff' : 'user'}/resend?username=${encodeURIComponent(
      username,
    )}`,
  );

const resetPassword = (username: string) =>
  axios.post('auth/reset', {
    username,
  });

const setPassword = (password: string, code: string) =>
  axios.put(`user/reset?code=${code}`, { password });

const unlockUser = (userId: string, isStaff = false) =>
  axios.get(`${isStaff ? 'staff' : 'user'}/${userId}/_unlock`);

const resetFactsetToken = (insCode: string) =>
  axios.get('auth/restateCookie', { params: { insCode } });

const verifyReCaptcha = (captchaResponse: string) =>
  axios.post('auth/recaptcha', { captchaResponse });

const getUserContactPoint = (userId: string | number) =>
  axios.get(`user/${userId}/contact`);

const sendUserFeedback = (
  userId: string | number,
  rating: number,
  comment: string,
) => axios.post('user/feedback', { userId, rating, comment });

const turnoffFeedback = () => axios.get('user/turnOffFeedBack');

const learnMore = (userId: string | number, type: string) =>
  axios.post(`user/${userId}/learnmore/${type}`);

const requestNewReport = (userId: string | number, companyName: string) =>
  axios.post(`user/${userId}/learnmore/OnDemandReport/${companyName}`);

const acceptTnc = (userId: string | number) =>
  axios.post(`user/${userId}/terms-and-conditions/accept`);

const getProfile = (userId: string | number) => axios.get(`user/${userId}`);

export default {
  login,
  logout,
  verifyOtp,
  resendOtp,
  resendInvitation,
  resetPassword,
  setPassword,
  unlockUser,
  resetFactsetToken,
  getUserContactPoint,
  sendUserFeedback,
  turnoffFeedback,
  learnMore,
  requestNewReport,
  verifyReCaptcha,
  acceptTnc,
  getProfile,
};
